import React, {ReactNode, useEffect, useState} from "react";
import Head from "next/head";
import shopApi from "../api/shop";
import MainLayout, {ILayout} from "../components/ForCWV/Wrappers/MainLayout";
import {useRouter} from "next/router";
import err from "../images/404.png";
import Link from "next/link";
import {domainUrl} from "../helper";
import Image from "next/image";

const NotFound = () => {
    const [content404, setContent404] = useState<ReactNode | null>(null);
    const router = useRouter();
    useEffect(() => {
        (async () => {
            const channelInfo = await shopApi.getChannelInfo404({ locale: router.locale })
            const { selectedLocale } = channelInfo
            const translationsData = await shopApi.postTranslationsNew404({ locale: selectedLocale })
            const socialsFooter = await shopApi.getSocialsFooter404()
            const {
                setMenuList: menus,
                setFooterMenuList: footerMenus
            } = await shopApi.getHeaderFooterMenus404({locale: selectedLocale});
            const coreConfigs = await shopApi.getCoreConfigs404( {selectedLocale, footerTrackItl: translationsData});
            const requestInfo = await shopApi.getRequestInfo404()
            const categories = await shopApi.getCategories404({ locale: selectedLocale, ONLY_FOR_TWO_KINGS: requestInfo.ONLY_FOR_TWO_KINGS })
            const pageProps = {
                ...coreConfigs,
                ...requestInfo,
                ...channelInfo,
                translationsData,
                socialsFooter,
                menus,
                footerMenus,
                categories
            }
            if (pageProps) {
                setContent404(
                    <MainLayout {...pageProps as ILayout}>
                        <div className="container">
                            <div className="not-found__con">
                                <div className="not-found__con__image">
                                    <Image
                                        src={domainUrl(`${requestInfo.dbName}${err.src}`)}
                                        alt="Error page image"
                                        placeholder="blur"
                                        layout={"fill"}
                                        blurDataURL={`/zega/blur1.webp`}
                                        objectFit='contain'
                                    />
                                </div>
                                <div className="not-found__con__text">
                                    <div className="text-con">
                                        <p className="not-found__con__text_p">
                                            {translationsData?.PageNotAvailable}
                                        </p>
                                        <p className="not-found__con__small_p">
                                            {translationsData?.SearchOrBack}
                                        </p>
                                        <Link href="/" className="not-found__con__text_a">
                                            <a>
                                                {translationsData?.GoToHome}
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MainLayout>
                )
            }
        })()
    }, [router.locale]);

    return (
        <div className="block">
            <Head>
                <title>404 Page Not Found</title>
            </Head>
            {content404}
        </div>
    );
}
export default NotFound;